import React from "react";
import { useEffect, useState } from "react";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import logo from '../assets/brand/logo.jpeg';
import Image from 'react-bootstrap/Image';

import { useNavigate } from 'react-router-dom'

const MyNavbar = () => {

	const navigate = useNavigate();

	function logout() {
		localStorage.removeItem('authenticated')
		localStorage.removeItem("FullName");
		localStorage.removeItem("Email");
		localStorage.removeItem("createPseudo")
		localStorage.removeItem("pseudo")
		navigate('/Home', { replace: true });
	}

	return (
		<Navbar expand="lg" bg="dark" data-bs-theme="dark">
			<Container fluid>
		<Navbar.Brand href="#Home">
			<Image
			  alt=""
			  src={logo}
			  thumbnail
			  width="30"
			  height="30"
			  className="d-inline-block align-top"
			/>{' '}Mac Game Toolkit</Navbar.Brand>
		<Navbar.Toggle aria-controls="basic-navbar-nav" />
		<Navbar.Collapse id="basic-navbar-nav">
			<Nav className="me-auto">
			<Nav.Link href="#Home">Home</Nav.Link>
			<Nav.Link href="#AllGames">All games list</Nav.Link>
			<NavDropdown title="Specific list" id="basic-nav-dropdown">
				<NavDropdown.Item href="#OKGames">
				Working games
				</NavDropdown.Item>
				<NavDropdown.Divider />
				<NavDropdown.Item href="#KOGames">Not Working games</NavDropdown.Item>
			</NavDropdown>
			<Nav.Link href="#AddGame">Add a game</Nav.Link>
			<Nav.Link href="#ASK">Ask for a game</Nav.Link>
			<NavDropdown title="Tutorials" id="basic-nav-dropdown">
				<NavDropdown.Item href='#Whisky'>Install Whisky</NavDropdown.Item>
				<NavDropdown.Item href="#CMD">Usefull command</NavDropdown.Item>
				<NavDropdown.Item href="#cxPatch">CX Patcher</NavDropdown.Item>
			</NavDropdown>
			<Nav.Link href="#About">About</Nav.Link>
			</Nav>
			<Button variant="primary" href="#Login" style={!localStorage.getItem('authenticated') ? {} : { display: 'none' }}>Login</Button>
			<div style={localStorage.getItem('authenticated') ? {} : { display: 'none' }}>
				<Button variant="danger" onClick={logout} >Logout{' '}{' '}</Button>
			</div>
		</Navbar.Collapse>
		</Container>
	</Navbar>
	);
};
 
export default MyNavbar;