import React from "react";
import { BsYoutube, BsDiscord, BsMailbox, BsUpc, BsFiletypeXml} from 'react-icons/bs';

import {
	Box,
	FooterContainer,
	Row,
	Column,
	FooterLink,
	Heading,
} from "./FooterStyles";
 
const Footer = () => {
	return (
		<div><br />
			<hr/>
		<Box>
			
			<h1
				style={{
					color: '#8FCED9',
					textAlign: "center",
				}}
			>
				Mac Game Toolkit website
			</h1>
			<FooterContainer>
			<Row>
				<Column>
					<Heading>Follow us</Heading>
					<FooterLink href="https://www.youtube.com/channel/UCf9PwwPELEHkJfeUZuxVCPQ" target="_blank">
						<BsYoutube size={20}/><span>  &nbsp; Youtube</span>
					</FooterLink>
					<FooterLink href="https://discord.gg/rD6nduX6af" target="_blank">
						<BsDiscord size={20}/><span>  &nbsp; Discord</span>
					</FooterLink>
				</Column>
				<Column>
					<Heading>Contact</Heading>
					<FooterLink href="antoine.souchet@gmil.com">
						<BsMailbox size={20}/><span>  &nbsp; email</span>
					</FooterLink>
				</Column>
				<Column>
					<Heading>Mentions</Heading>
					<FooterLink href="#CGU">
						<BsUpc size={20}/><span>  &nbsp; CGU</span>
					</FooterLink>
				</Column>
				<Column>
					<Heading>Plan</Heading>
					<FooterLink href="sitemap.xml">
						<BsFiletypeXml size={20}/><span>  &nbsp; Plan</span>
					</FooterLink>
				</Column>
			</Row>
			</FooterContainer>
		</Box>		</div>
	);
};
export default Footer;