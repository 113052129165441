import React from 'react';
import Container from 'react-bootstrap/Container';
import { useState } from 'react';
import { BsZoomIn } from "react-icons/bs";
import { FcOk, FcHighPriority, FcEngineering, FcEmptyTrash} from "react-icons/fc";

import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';

import errorImage from '../assets/404/unplug.jpeg'

import getAllGame from '../services/GameBackEnd.js'
import axios from "axios";

function AllGames() {

	const [show, setShow] = useState(false);
	const [url , setUrl] = useState(false);
	const [myGames, setMyGames] = useState(false);
	const [modalName , setmodalName] = useState(false);
	const [dataGame, setDataGame] = useState(false);
	const [showT, setShowT] = React.useState(false);
	const [userName, setUserName] = React.useState(false);
	const [stopCall, setStopCall] = React.useState(false);
	const [showDetail, setShowDetail] = React.useState(false);

	//TODO strange infinity Call ???
	if (stopCall == false) {
		getAllGame().then((value) => {
			setShowT(true);
			setMyGames(value)
			setStopCall(true)
		})
	}

	function getUsername(id) {
		try {
			axios.get('/api/getUser/' + id,null,{ headers: { "Content-type": "application/json"}})
			.then((response) => {
				setUserName(response.data.pseudo)

			})
			.catch((error) => {
				console.log(error)
			})
			.error((response) => {
				console.log(response)
			})
		} catch (error) {
			
		}
	}

	const handleClose = () => setShow(false);
	const handleCloseDetail  = () => setShowDetail(false);
	const handleShow = (data) => setShow(true);

	function bigSize(name,data) {
		setShow(true)
		setUrl(data)
		setmodalName(name)
	}

	function getDetails(row, id) {
		setShowDetail(true)
		setmodalName(row.name)
		setDataGame(row)
	}

	function imgFormatter (cell, row) {
		if (cell === "") {
			return (
				<Image src={errorImage} style={{"width":"250px"}} thumbnail/>
			);
		} else if (cell == "uploads/") {
		return (
			<Image src={errorImage} style={{"width":"250px"}} thumbnail/>
		); 
		} else if (cell == null)
			return (
				<Image src={errorImage} style={{"width":"250px"}} thumbnail/>
			);
		else {
			return (
				<Image src={cell} style={{"width":"250px"}} thumbnail onClick={() => bigSize(row.name,cell)}/>
			);
		}

	}

	function titleFormatter (cell, row) {
		//11/11/2023 23:32:06
		var theDate = row.mydate.split(' ')
		var myDate = theDate[0].split('/');
		myDate = myDate[2] + '-' + myDate[1] + '-' + myDate[0] + ' ' + theDate[1]
		return (
			<span>
				<h3>{row.name}</h3>
				<h6><i>On {row.chipset} / {row.method} - {myDate}</i></h6>
				<p><i>{row.description}</i></p>
			</span>
		);
	}

	function detailsFormatter (cell ,row) {
		return (
		<span><br />
			<Button onClick={() => getDetails(row,cell)}><BsZoomIn/></Button>
		  </span>
		);
	}

	function resultsFormatter(cell, row) {
		if (cell == "OK") {
		  return (
			<span>
			  <strong style={ { color: 'green' } }>Game runs perfectly <FcOk /></strong>
			</span>
		  )
		} else if (cell == "OKBUT") {
			return (
			  <span>
				<strong style={ { color: 'orange' } }>Runs with problem <FcEngineering></FcEngineering></strong>
			  </span>
			)
		} else if (cell == "OKBAD") {
			return (
			  <span>
				<strong style={ { color: 'purple' } }>Runs but unplayable <FcEmptyTrash></FcEmptyTrash></strong>
			  </span>
			)
		}
		else {
			return (
			  <span>
				<strong style={ { color: 'red' } }>Not working <FcHighPriority></FcHighPriority></strong>
			  </span>
			);
	  }
	}

	function getUrl(youtubeUrl) {
		try {
			var splitUrl = youtubeUrl.split('?')
			var secondSplit = splitUrl[1].split('&')
			youtubeUrl = 'https://www.youtube.com/embed/' + secondSplit[0].replace('v=','') + '?autoplay=0';
			return youtubeUrl;
		} catch(error) {
			return null
		}
	}

	const columns = [{
		dataField: 'image',
		text: '',
		style: { width: '200px' },
		formatter: imgFormatter
	}, {
		dataField: 'name',
		text: 'Name',
		filter: textFilter({
			placeholder: 'Search game name',  // custom the input placeholder
			className: 'my-custom-text-filter', // custom classname on input
			caseSensitive: false, // default is false, and true will only work when comparator is LIKE
			delay: 1000, // how long will trigger filtering after user typing, default is 500 ms
			id: 'id', // assign a unique value for htmlFor attribute, it's useful when you have same dataField across multiple table in one page
		  }),
		formatter: titleFormatter,
		sort: true
	},
	{
		dataField: 'result',
		text: 'Result',
		align:'center',
		formatter: resultsFormatter
	},
	{
		dataField: 'id',
		text: 'Details',
		align:'center',
		formatter: detailsFormatter
	}];

	return (
			<Container><div id="880871552">
			<script type="text/javascript">
				try {
					window._mNHandle.queue.push(function (){
						window._mNDetails.loadTag("880871552", "728x90", "880871552");
					})
				}
				catch (error) {}
			</script>
		</div><br /><br />
					<Modal 
						show={show} 
						size='xl'
						dialogClassName="modal-90w"
						aria-labelledby="example-custom-modal-styling-title"
						onHide={handleClose} >
						<Modal.Header closeButton>
						<Modal.Title id="example-custom-modal-styling-title">{modalName}</Modal.Title>
						</Modal.Header>
						<Modal.Body><center><img src={url} className="img-fluid"/></center>
							
						</Modal.Body>
						<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>
						</Modal.Footer>
					</Modal>

					<Modal 
						show={showDetail} 
						size='xl'
						dialogClassName="modal-90w"
						aria-labelledby="example-custom-modal-styling-title"
						onHide={handleCloseDetail} >
						<Modal.Header closeButton>
						<Modal.Title id="example-custom-modal-styling-title">{modalName}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div class="backImage" style={{
									background: `linear-gradient(to bottom, transparent 0%, #212529 70%), url(${dataGame.image})`
						}}>
							<br />
							<p class="textModalGame"><b>Report date </b>: {dataGame.mydate}</p>
							<p class="textModalGame"><b>Results :  {resultsFormatter(dataGame.result)}</b></p>
							<p class="textModalGame"><b>Chipset </b>: {dataGame.chipset} with {dataGame.ram} RAM and {dataGame.method}</p>
							<p class="textModalGame" ><b>Description </b>: {dataGame.description}</p>
							{dataGame.settings != "" &&  (<p class="textModalGame"><b>Settings </b> : {dataGame.settings}</p>)}
							<p class="textModalGame"><b>Framerate </b>: {dataGame.framerate} with <b>resolution</b> : {dataGame.resolution}</p>
							<p class="textModalGame"><b>Controller </b>: {dataGame.controller} </p>
						<center>
							{dataGame.youtube != "" &&  (<div class="video-container"><br /><iframe width="420" height="315"
								src={getUrl(dataGame.youtube)}>	
							</iframe> </div>)}
							<br />
	
							{dataGame.iduser == 0 &&  (<p>Test by <i>Anonymous</i></p>)} 
							{dataGame.iduser != 0 &&  (<p>Test by <i>{getUsername(dataGame.iduser)} {userName}</i></p>)} 
						</center>
							</div>
						</Modal.Body>
						<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseDetail}>
							Close
						</Button>
						</Modal.Footer>
					</Modal>
					{showT && (
						<BootstrapTable keyField='id' data={ myGames } columns={ columns } filter={ filterFactory() } pagination={ paginationFactory() } />
					)}
			</Container>
		)
}

export default AllGames;