import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

import imgWhisky from '../assets/tuto/whisky.png'
import imgWhisky2 from '../assets/tuto/whisky2.png'

class Whisky extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Container>
				<br />
				<h1>Install Apple GPTK</h1>
				<p>
					Manual installation is complex and time-consuming without any extra result.. You can follow this tuto : <a href="https://www.applegamingwiki.com/wiki/Game_Porting_Toolkit" target="_blank">here</a>
					<br /><br />
					<h2>Use Whisky</h2>
					Video to install it : <br />
					<iframe width="560" height="315" src="https://www.youtube.com/embed/MTJ-ZvvAzIc?si=sFOiVUDmkhWj2eOU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
					<br /><br />The best option is to use Whisky! Download it <a href="https://github.com/Whisky-App/Whisky/releases" target="_blank">here</a>
					<Image src={imgWhisky} fluid></Image><br /><br /><br />
					Unzip folder and place application in your Application folder. Start it and create your first bottle for Steam (or battle.net), and voila !
					<Image src={imgWhisky2} fluid></Image>
				</p>
			</Container>

		);
	}
}

export default Whisky;